// ** Third Party Components
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

const IntlDropdown = () => {
	// ** Hooks
	const { i18n } = useTranslation()

	// ** Vars
	const langObj = {
		ru: 'Русский',
		ro: 'Română'
	}

	// ** Check local storage for language preference on app start
	useEffect(() => {
		const storedLang = localStorage.getItem('selectedLang')
		if (storedLang) {
			i18n.changeLanguage(storedLang)
		}
	}, [i18n])

	// ** Function to switch Language
	const handleLangUpdate = (e, lang) => {
		e.preventDefault()
		i18n.changeLanguage(lang)
		localStorage.setItem('selectedLang', lang) // Store selected language in local storage
	}

	return (
		<UncontrolledDropdown href='/' tag='li' className='dropdown-language nav-item'>
			<DropdownToggle href='/' tag='a' className='nav-link' onClick={(e) => e.preventDefault()}>
				<ReactCountryFlag svg className='country-flag flag-icon' countryCode={i18n.language === 'en' ? 'us' : i18n.language} />
				<span className='selected-language'>{langObj[i18n.language]}</span>
			</DropdownToggle>
			<DropdownMenu className='mt-0' end>
				<DropdownItem href='/' tag='a' onClick={(e) => handleLangUpdate(e, 'ru')}>
					<ReactCountryFlag className='country-flag' countryCode='ru' svg />
					<span className='ms-1'>Русский</span>
				</DropdownItem>
				<DropdownItem href='/' tag='a' onClick={(e) => handleLangUpdate(e, 'ro')}>
					<ReactCountryFlag className='country-flag' countryCode='ro' svg />
					<span className='ms-1'>Română</span>
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	)
}

export default IntlDropdown
