// ** Icons Import
import { Mail } from 'react-feather'

export default [
	{
		header: 'MAILBOX',
		action: 'manage',
		resource: 'mailbox'
	},
	{
		id: 'mailbox',
		title: 'Mailbox',
		icon: <Mail size={20} />,
		action: 'manage',
		resource: 'mailbox',
		navLink: '/mailbox'
	}
]
