// ** Icons Import
import { Book, BookOpen, HelpCircle } from 'react-feather'

export default [
	{
		header: 'LMS',
		action: 'manage',
		resource: 'lms'
	},
	{
		id: 'lmsCourses',
		title: 'Courses',
		icon: <Book size={20} />,
		action: 'manage',
		resource: 'lms',
		navLink: '/lms/courses'
	},
	{
		id: 'lmsLessons',
		title: 'Lessons',
		icon: <BookOpen size={20} />,
		action: 'manage',
		resource: 'lms',
		navLink: '/lms/lessons'
	},
	{
		id: 'lmsQuizzes',
		title: 'Quizzes',
		icon: <HelpCircle size={20} />,
		action: 'manage',
		resource: 'lms',
		navLink: '/lms/quizzes'
	},
	{
		id: 'myCourses',
		title: 'My courses',
		icon: <Book size={20} />,
		action: 'pass',
		resource: 'courses',
		navLink: '/lms/my-courses'
	}
]
