import { lazy } from 'react'

const DashboardAnalytics = lazy(() => import('../../views/dashboard/analytics'))

const DashboardRoutes = [
	{
		path: '/dashboard/analytics',
		meta: {
			action: 'view',
			resource: 'dashboard'
		},
		element: <DashboardAnalytics />
	}
]

export default DashboardRoutes
