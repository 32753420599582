import { lazy } from 'react'

const Settings = lazy(() => import('../../views/settings'))

const SettingsRoutes = [
	{
		path: '/settings',
		meta: {
			action: 'manage',
			resource: 'settings'
		},
		element: <Settings />
	}
]

export default SettingsRoutes
