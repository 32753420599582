import { lazy } from 'react'

const LmsCourses = lazy(() => import('../../views/lms/courses'))
const LmsLessons = lazy(() => import('../../views/lms/lessons'))
const LmsQuizzes = lazy(() => import('../../views/lms/quizzes'))
const MyCourses = lazy(() => import('../../views/lms/courses/my-courses'))
const CourseLessons = lazy(() => import('../../views/lms/courses/lessons'))
const LearnLesson = lazy(() => import('../../views/lms/courses/learn'))
// const Quiz = lazy(() => import('../../views/lms/quizzes/start'))

const LmsRoutes = [
	{
		path: '/lms/courses',
		meta: {
			action: 'manage',
			resource: 'lms'
		},
		element: <LmsCourses />
	},
	{
		path: '/lms/lessons',
		meta: {
			action: 'manage',
			resource: 'lms'
		},
		element: <LmsLessons />
	},
	{
		path: '/lms/quizzes',
		meta: {
			action: 'manage',
			resource: 'lms'
		},
		element: <LmsQuizzes />
	},
	{
		path: '/lms/my-courses',
		meta: {
			action: 'pass',
			resource: 'courses'
		},
		element: <MyCourses />
	},
	{
		path: '/lms/course/:id/lessons',
		meta: {
			action: 'pass',
			resource: 'courses'
		},
		element: <CourseLessons />
	},
	{
		path: '/lms/lesson/:id/learn',
		meta: {
			action: 'pass',
			resource: 'courses'
		},
		element: <LearnLesson />
	}
	/*{
		path: '/lms/quiz/:id/start',
		meta: {
			action: 'pass',
			resource: 'courses'
		},
		element: <Quiz />
	}*/
]

export default LmsRoutes
