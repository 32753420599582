// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAddresBook = createAsyncThunk('appEmail/getAddressBook', async () => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/address-book/get-contacts`)
	return {
		data: response.data
	}
})

export const getFolders = createAsyncThunk('appEmail/getFolders', async ({ account }) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mailbox/folders`, {
		params: { account: account.username }
	})
	// console.log('Dispatched folders: ', response.data)
	return {
		data: response.data
	}
})

export const createFolder = createAsyncThunk('appEmail/createFolder', async ({ account, folder }) => {
	console.log('Folder to save: ', folder)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/folders/add-new`, {
		account: account.username,
		folder
	})
	return response.data
})

export const deleteFolder = createAsyncThunk('appEmail/deleteFolder', async ({ account, folder }) => {
	console.log('Folder to delete: ', folder)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/folders/delete`, {
		account: account.username,
		folder
	})
	return response.data
})

export const getMails = createAsyncThunk('appEmail/getMails', async ({ account, folder }) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mailbox/folders/${folder}`, {
		params: { account: account.username }
	})
	// console.log('Dispatched mails: ', response.data)
	return {
		params: { account, folder },
		data: response.data
	}
})

export const searchMails = createAsyncThunk('appEmail/searchMails', async ({ account, sq }) => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mailbox/search`, {
		params: { account: account.username, sq }
	})
	console.log('Search result: ', response.data)
	return {
		params: { account, sq },
		data: response.data
	}
})

export const updateMails = createAsyncThunk('appEmail/updateMails', async ({ emailIds, dataToUpdate }, { dispatch, getState }) => {
	const response = await axios.post('/apps/email/update-emails', { emailIds, dataToUpdate })
	await dispatch(getMails(getState().email.params))
	return {
		emailIds,
		dataToUpdate,
		data: response.data
	}
})

export const updateMailLabel = createAsyncThunk('appEmail/updateMailLabel', async ({ emailIds, label }, { dispatch, getState }) => {
	const response = await axios.post('/apps/email/update-emails-label', { emailIds, label })
	await dispatch(getMails(getState().email.params))
	return response.data
})

export const paginateMail = createAsyncThunk('appEmail/paginateMail', async ({ dir, emailId }) => {
	const response = await axios.get('/apps/email/paginate-email', { params: { dir, emailId } })
	return response.data
})

export const selectCurrentMail = createAsyncThunk('appEmail/selectCurrentMail', async ({ account, activeFolder, folder, uid }) => {
	console.log(`Dispatching email for ${account.username} from ${folder}`)
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mailbox/email/read/${folder}/${uid}`, {
		params: { account: account.username }
	})
	console.log('Dispatched email: ', response.data)
	return {
		activeFolder, // Active folder ( mail.folder can be defferent if mail is in Unread or Marked folder )
		data: response.data
	}
})

export const moveMails = createAsyncThunk('appEmail/moveMails', async ({ account, mails, to }) => {
	console.log('Mails to move: ', mails)
	console.log('Destination folder: ', to)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/emails/move`, {
		account: account.username,
		mails,
		to
	})
	return response.data
})

export const sendMail = createAsyncThunk('appEmail/sendMail', async ({ account, email }) => {
	console.log('Mail to send: ', email)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/email/send`, {
		account: account.username,
		email
	})
	return response.data
})

export const saveMail = createAsyncThunk('appEmail/saveMail', async ({ account, email }) => {
	console.log('Mail to save: ', email)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/email/save`, {
		account: account.username,
		email
	})
	return response.data
})

/*export const deleteMails = createAsyncThunk('appEmail/delteMails', async (uids) => {
	console.log('Uids to delete: ', uids)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/emails/delete`, { uids })
	return response.data
})*/

export const flagMails = createAsyncThunk('appEmail/flagMails', async ({ account, uids, folder }) => {
	console.log('Uids to flag: ', uids)
	const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/mailbox/emails/flag`, {
		account: account.username,
		uids,
		folder
	})
	console.log('Flagged: ', response)
	return response.data
})

export const appEmailSlice = createSlice({
	name: 'appEmail',
	initialState: {
		folders: [],
		folder: null,
		mails: [],
		params: {},
		emailsMeta: {},
		selectedMails: {},
		currentMail: null,
		addressBook: [],
		searchResults: []
	},
	reducers: {
		selectMail: (state, action) => {
			const { messageId, folder, uid } = action.payload
			const selected = { ...state.selectedMails }
			if (!selected[messageId]) {
				selected[messageId] = { folder, uid }
			} else {
				delete selected[messageId]
			}
			state.selectedMails = selected
		},
		selectAllMail: (state, action) => {
			const { checked, folder } = action.payload
			const selected = {}
			if (checked) {
				//state.mails[folder].forEach((mail) => selectAllMailsArr.push(mail.messageId))
				state.mails[folder].forEach((mail) => (selected[mail.messageId] = { folder: mail.folder, uid: mail.uid }))
				state.selectedMails = selected
			} else {
				state.selectedMails = []
			}
		},
		resetSelectedMail: (state) => {
			state.selectedMails = []
		},
		resetSearchResults: (state) => {
			state.searchResults = []
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAddresBook.fulfilled, (state, action) => {
				console.log('Addresses dispatched...')
				state.addressBook = action.payload.data
			})
			.addCase(getFolders.fulfilled, (state, action) => {
				console.log('Folders dispatched...')
				state.folders = action.payload.data
			})
			.addCase(createFolder.fulfilled, (state, action) => {
				if (action.payload.created) {
					state.folders = [...state.folders, action.payload.folder]
				}
			})
			.addCase(deleteFolder.fulfilled, (state, action) => {
				if (action.payload.deleted) {
					state.folders = state.folders.filter((folder) => folder.name !== action.payload.folder)
				}
			})
			.addCase(getMails.fulfilled, (state, action) => {
				console.log('Emails dispatched...')
				let currMail = null

				// Check if the current mail exists and update it if needed
				if (state.currentMail !== null && state.currentMail !== undefined) {
					currMail = action.payload.data.emails.find((i) => i.uid === state.currentMail.uid)
				}

				state.currentMail = currMail
				state.params = action.payload.params
				state.folder = action.payload.data.folder

				// Ensure the emails are stored in the right folder
				const folderPath = action.payload.data.folder.path
				const emails = action.payload.data.emails
				const emailsMeta = action.payload.data.emailsMeta

				// Store the emails for the current folder separately
				if (!state.mails) {
					state.mails = {}
				}

				state.mails = {
					...state.mails,
					[folderPath]: emails
				}

				// Similarly, store emails metadata separately for each folder
				if (!state.emailsMeta) {
					state.emailsMeta = {}
				}

				state.emailsMeta = {
					...state.emailsMeta,
					[folderPath]: emailsMeta
				}
				/*let currMail = null
				if (state.currentMail !== null && state.currentMail !== undefined) {
					currMail = action.payload.data.emails.find(
						(i) => i.uid === state.currentMail.uid
					)
				}
				state.currentMail = currMail
				state.params = action.payload.params
				state.folder = action.payload.data.folder
				state.mails = action.payload.data.emails
				state.emailsMeta = action.payload.data.emailsMeta*/
			})
			.addCase(searchMails.fulfilled, (state, action) => {
				state.params = action.payload.params
				state.searchResults = action.payload.data.emails
			})
			.addCase(flagMails.fulfilled, (state, action) => {
				const ids = Object.keys(action.payload)
				Object.keys(state.mails).forEach((folder) => {
					state.mails[folder].forEach((email) => {
						if (ids.includes(email.messageId)) {
							Object.assign(email, { flags: action.payload[email.messageId] })
						}
					})
				})
			})
			.addCase(moveMails.fulfilled, (state, action) => {
				Object.keys(state.mails).forEach((folder) => {
					const mails = [...state.mails[folder]]
					const moved = []
					// Array is mutated during spliceing, so some lements are skipped and not removed, filtering will be used instead
					/*
						mails.forEach((email, index) => {
							if (action.payload.moved.includes(email.messageId)) {
								moved.push({ ...email }) // Ensure deep copy of email object to avoid proxy issues
								mails.splice(index, 1) // Remove the email from array
							}
						})
					*/

					const filtered = mails.filter((email) => {
						if (action.payload.moved.includes(email.messageId)) {
							moved.push({ ...email }) // Deep copy email to avoid proxy issues
							return false // Exclude the email from the new array
						}
						return true // Keep the email in the array
					})

					state.mails = {
						...state.mails,
						[folder]: filtered
					}

					/**
					 * Before moving, check if folder was already loaded, if not, do nothing
					 * Mails will be loaded when folder will be accessed
					 */
					if (state.mails[action.payload.to.path]) {
						state.mails = {
							...state.mails,
							[action.payload.to.path]: [
								...state.mails[action.payload.to.path],
								...moved // Spread the moved array to avoid nesting it as an array within an array
							]
						}
					}
				})

				const from = Object.keys(action.payload.from)
				state.folders.forEach((folder) => {
					if (from.includes(folder.path)) {
						Object.assign(folder, { exists: action.payload.from[folder.path].exists })
					}
					if (action.payload.to.path === folder.path) {
						Object.assign(folder, { exists: action.payload.to.exists })
					}
				})
			})
			.addCase(sendMail.fulfilled, (state, action) => {
				if (action.payload.sent) {
					// Only add to 'Sent' if it already exists in state.mails
					// Otherwise emails will be loaded when the folder will be accessed
					if (state.mails['Sent']) {
						state.mails['Sent'].unshift({ ...action.payload.email })
					}

					// Remove the draft if Drafts folder is loaded and draft exists
					if (state.mails['Drafts']) {
						state.mails['Drafts'] = state.mails['Drafts'].filter((draft) => draft.messageId !== action.payload.email.messageId)
					}
					// Update the 'Sent' folder's total email count if it exists
					const sentFolder = state.folders.find((folder) => folder.path === 'Sent')
					if (sentFolder) {
						sentFolder.exists = action.payload.totalSent
					}

					// Update the 'Drafts' folder's total email count if it exists
					const draftsFolder = state.folders.find((folder) => folder.path === 'Drafts')
					if (draftsFolder) {
						draftsFolder.exists = action.payload.totalDrafts
					}
				}
			})
			.addCase(saveMail.fulfilled, (state, action) => {
				if (action.payload.saved) {
					// Only add to 'Sent' if it already exists in state.mails
					// Otherwise emails will be loaded when the folder will be accessed
					if (state.mails['Drafts']) {
						state.mails['Drafts'].unshift({ ...action.payload.email })
					}

					// Update the 'Drafts' folder's total email count if it exists
					const draftsFolder = state.folders.find((folder) => folder.path === 'Drafts')
					if (draftsFolder) {
						draftsFolder.exists = action.payload.totalDrafts
					}
				}
			})
			.addCase(updateMails.fulfilled, (state, action) => {
				function updateMailData(email) {
					Object.assign(email, action.payload.dataToUpdate)
				}
				state.mails.forEach((email) => {
					if (action.payload.emailIds.includes(email.uid)) {
						updateMailData(email)
					}
				})
			})
			.addCase(paginateMail.fulfilled, (state, action) => {
				const data = action.payload
				const dataIndex = state.mails.findIndex((i) => i.uid === data.uid)
				dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
				dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
				state.currentMail = data
			})
			.addCase(selectCurrentMail.fulfilled, (state, action) => {
				const mail = action.payload.data
				const activeFolder = action.payload.activeFolder
				const mails = [...state.mails[activeFolder.path]]

				const index = mails.findIndex((i) => i.messageId === mail.messageId)
				// Update the mail
				const updatedMail = {
					...mails[index],
					flags: {
						...mails[index].flags, // Copy the existing flags
						seen: 'Seen' // Add seen flag
					}
				}
				// Replace the mail at the found index with the updated mail
				mails[index] = updatedMail

				// Update the state with the modified mails array for the folder
				state.mails = {
					...state.mails,
					[activeFolder.path]: mails
				}

				state.currentMail = mail

				// Update unseen count in both the target folder and the 'Unread' folder if they have unseen messages
				state.folders = state.folders.map((folder) => {
					if (folder.path === mail.folder && folder.unseen > 0) {
						// Decrease unseen count for the target folder
						return { ...folder, unseen: folder.unseen - 1 }
					}
					if (folder.path === 'Unread' && folder.exists > 0) {
						// Decrease unseen count for the 'Unread' folder
						return { ...folder, exists: folder.exists - 1 }
					}
					return folder
				})

				// Remove the message from 'Unread' folder in state.mails if it exists
				if (state.mails['Unread']) {
					state.mails['Unread'] = state.mails['Unread'].filter((unreadMail) => unreadMail.messageId !== mail.messageId)
				}
			})
	}
})

export const { selectMail, selectAllMail, resetSelectedMail, resetSearchResults } = appEmailSlice.actions

export default appEmailSlice.reducer
