import { lazy } from 'react'
const HrmCompanies = lazy(() => import('../../views/hrm/companies'))
const HrmBranches = lazy(() => import('../../views/hrm/branches'))
const HrmDepartments = lazy(() => import('../../views/hrm/departments'))
const HrmFunctions = lazy(() => import('../../views/hrm/functions'))
const HrmLocalities = lazy(() => import('../../views/hrm/localities'))
const HrmEmployees = lazy(() => import('../../views/hrm/employees'))
const HrmEmployeeProfile = lazy(() => import('../../views/hrm/employees/profile'))

const HrmRoutes = [
	{
		path: '/hrm/companies',
		meta: {
			action: 'manage',
			resource: 'hrm'
		},
		element: <HrmCompanies />
	},
	{
		path: '/hrm/branches',
		meta: {
			action: 'manage',
			resource: 'hrm'
		},
		element: <HrmBranches />
	},
	{
		path: '/hrm/departments',
		meta: {
			action: 'manage',
			resource: 'hrm'
		},
		element: <HrmDepartments />
	},
	{
		path: '/hrm/functions',
		meta: {
			action: 'manage',
			resource: 'hrm'
		},
		element: <HrmFunctions />
	},
	{
		path: '/hrm/employees',
		meta: {
			action: 'manage',
			resource: 'hrm'
		},
		element: <HrmEmployees />
	},
	{
		path: '/hrm/employees/profile/:id',
		meta: {
			action: 'manage',
			resource: 'hrm'
		},
		element: <HrmEmployeeProfile />
	},
	{
		path: '/hrm/localities',
		meta: {
			action: 'manage',
			resource: 'hrm'
		},
		element: <HrmLocalities />
	}
]

export default HrmRoutes
