// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
//import email from '@src/views/apps/email/store'
import email from '@src/views/mailbox/store'

const rootReducer = {
	auth,
	email,
	navbar,
	layout
}

export default rootReducer
