// ** Navigation imports
import auth from './auth'
import dashboards from './dashboards'
import hrm from './hrm'
import lms from './lms'
import mailbox from './mailbox'
// import settings from './settings'

// ** Merge & Export
export default [...dashboards, ...hrm, ...lms, ...mailbox, ...auth]
