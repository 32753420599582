// ** React Imports
import { Suspense } from 'react'
import { Navigate } from 'react-router-dom'
// import { useSelector } from 'react-redux'

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from '@utils'

const PublicRoute = ({ children, route }) => {
	if (route) {
		const user = getUserData()
		console.log('User data from public routes', user)
		// const user = useSelector((state) => state.auth.userData)

		const restrictedRoute = route.meta && route.meta.restricted

		if (user && restrictedRoute) {
			return <Navigate to={getHomeRouteForLoggedInUser(user.role)} />
		}
	}

	return <Suspense fallback={null}>{children}</Suspense>
}

export default PublicRoute
