import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

import { getUserData } from '@utils'

// Get the user data dynamically
const userData = getUserData()
const existingAbility = userData ? userData.ability : null

// Export Ability instance with either the user's abilities or initial abilities
export default new Ability(existingAbility || initialAbility)
