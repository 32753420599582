// ** Auth Endpoints
export default {
	//loginEndpoint: '/jwt/login',
	loginEndpoint: `${process.env.REACT_APP_API_URL}/api/auth/login`,
	verifyPinEndpoint: `${process.env.REACT_APP_API_URL}/api/auth/verify-pin`,
	refreshEndpoint: `${process.env.REACT_APP_API_URL}/api/auth/refresh-token`,
	// registerEndpoint: '/jwt/register',
	// logoutEndpoint: '/jwt/logout',

	// ** This will be prefixed in authorization header with token
	// ? e.g. Authorization: Bearer <token>
	tokenType: 'Bearer',

	// ** Value of this property will be used as key to store JWT token in storage
	storageTokenKeyName: 'accessToken',
	storageRefreshTokenKeyName: 'refreshToken'
}
