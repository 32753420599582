// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const layoutSlice = createSlice({
	name: 'layout',
	initialState: {
		query: '',
		bookmarks: [],
		suggestions: []
	},
	reducers: {
		handleSearchQuery: (state, action) => {
			state.query = action.payload
		}
	}
})

export const { handleSearchQuery } = layoutSlice.actions

export default layoutSlice.reducer
