// ** React Imports
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Utils
import { isUserLoggedIn, getUserData } from '@utils'

// ** Store & Actions
import { useDispatch /*useSelector*/ } from 'react-redux'
import { handleLogout } from '@store/authentication'

// ** Third Party Components
import axios from 'axios'
import { Power, Settings } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/avatars/avatar-blank.png'

// console.log('default avatar: ', defaultAvatar)

const UserDropdown = () => {
	const { t } = useTranslation()

	// ** Store Vars
	const dispatch = useDispatch()

	// ** State
	const [userData, setUserData] = useState(null)

	//** ComponentDidMount
	useEffect(() => {
		if (isUserLoggedIn()) {
			const data = getUserData() // Access userData from Redux instead of localStorage
			setUserData(data)
		}
	}, [])

	//** Vars
	const userAvatar = (userData && userData.avatar && `${process.env.REACT_APP_API_URL}${userData.avatar}`) || defaultAvatar

	const logout = () => {
		axios.post(`${process.env.REACT_APP_API_URL}/api/auth/logout`).then((response) => {
			console.log('Logout performed: ', response)
			dispatch(handleLogout())
			// Redirect to login page
			window.location.href = '/login'
		})
	}

	return (
		<UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
			<DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={(e) => e.preventDefault()}>
				<div className='user-nav d-sm-flex d-none'>
					<span className='user-name fw-bold'>{(userData && userData['fullName']) || 'John Doe'}</span>
					<span className='user-status'>{(userData && userData.role) || 'Admin'}</span>
				</div>
				<Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' />
			</DropdownToggle>
			<DropdownMenu end>
				<DropdownItem tag={Link} to='/settings'>
					<Settings size={14} className='me-75' />
					<span className='align-middle'>{t('Settings')}</span>
				</DropdownItem>
				<DropdownItem
					tag={Link}
					to='/logout'
					onClick={(e) => {
						e.preventDefault()
						logout()
					}}
				>
					<Power size={14} className='me-75' />
					<span className='align-middle'>{t('Logout')}</span>
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	)
}

export default UserDropdown
