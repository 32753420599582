import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
	jwtConfig = { ...jwtDefaultConfig }
	isRefreshing = false
	pendingRequests = []

	constructor(jwtOverrideConfig, dispatch) {
		this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
		this.dispatch = dispatch
	}

	setupInterceptors() {
		const isDevMode = process.env.REACT_APP_NODE_ENV === 'development'
		// ** Request Interceptor
		axios.interceptors.request.use(
			(config) => {
				config.withCredentials = true // Ensure cookies are included

				// Add Authorization header in development mode
				if (isDevMode && config.url !== this.jwtConfig.refreshEndpoint) {
					const accessToken = localStorage.getItem('accessToken')
					if (accessToken) {
						config.headers['Authorization'] = `Bearer ${accessToken}`
					}
				}
				return config
			},
			(error) => Promise.reject(error)
		)

		// ** Response Interceptor
		axios.interceptors.response.use(
			(response) => response,
			async (error) => {
				const { config, response } = error

				// Handle 401 Unauthorized
				if (response && response.status === 401 && !config._retry) {
					console.log('Performing token refresh...')
					config._retry = true

					if (!this.isRefreshing) {
						this.isRefreshing = true

						try {
							const newAccessToken = await this.refreshToken()
							this.isRefreshing = false

							// Resolve all pending requests with the new token
							this.pendingRequests.forEach((callback) => callback(newAccessToken))
							this.pendingRequests = []

							// Retry the original request with the new token
							config.headers['Authorization'] = `Bearer ${newAccessToken}`
							return axios(config)
						} catch (refreshError) {
							console.error('Token refresh failed:', refreshError)
							this.isRefreshing = false
							this.pendingRequests = []
							// this.logout() // Logout the user if token refresh fails
							return Promise.reject(refreshError)
						}
					}

					// Queue requests until the token is refreshed
					return new Promise((resolve) => {
						this.pendingRequests.push((token) => {
							config.headers['Authorization'] = `Bearer ${token}`
							resolve(axios(config))
						})
					})
				}

				return Promise.reject(error)
			}
		)
	}

	// Login method
	login(...args) {
		return axios.post(this.jwtConfig.loginEndpoint, ...args)
	}

	// Verify pin method
	verifyPin(...args) {
		return axios.post(this.jwtConfig.verifyPinEndpoint, ...args)
	}

	// Logout method
	logout = async () => {
		console.log('Performing logout due to 401 or token refresh failure...')
		try {
			// Notify the backend of logout
			await axios.post(this.jwtConfig.logoutEndpoint, {}, { withCredentials: true })

			// Clear tokens from localStorage
			localStorage.removeItem('userData')
			localStorage.removeItem('accessToken')
			localStorage.removeItem('refreshToken')

			// Redirect to login page
			window.location.href = '/login'
		} catch (error) {
			console.error('Logout request failed:', error)

			// Still clear tokens and redirect
			localStorage.removeItem('userData')
			localStorage.removeItem('accessToken')
			localStorage.removeItem('refreshToken')
			window.location.href = '/login'
		}
	}

	refreshToken = async () => {
		const isDevMode = process.env.REACT_APP_NODE_ENV === 'development' // Check environment mode

		try {
			// Retrieve refresh token only in development mode
			const refreshToken = isDevMode ? localStorage.getItem('refreshToken') : null

			// Ensure refresh token is present in development mode
			if (isDevMode && !refreshToken) {
				throw new Error('No refresh token available in development mode')
			}

			// Set headers dynamically
			const headers = isDevMode ? { Authorization: `Refresh ${refreshToken}` } : {}

			// Make the refresh token request
			const response = await axios.post(
				this.jwtConfig.refreshEndpoint,
				{}, // No payload needed
				{
					withCredentials: true, // Ensure cookies are included in production
					headers // Use headers if needed
				}
			)

			const { accessToken, refreshToken: newRefreshToken } = response.data

			// Store tokens in localStorage in development mode
			if (isDevMode) {
				localStorage.setItem('accessToken', accessToken)
				localStorage.setItem('refreshToken', newRefreshToken)
			}

			return accessToken // Return the new access token
		} catch (error) {
			console.error('Failed to refresh token:', error)
			// Handle token refresh failure (e.g., redirect to login or notify user)
			// this.logout()
			throw error // Rethrow to handle in interceptors
		}
	}
}
