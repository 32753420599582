// ** React Imports
import { Navigate } from 'react-router-dom'
import { useContext, Suspense } from 'react'
import { getUserData } from '@utils'

// ** Context Imports
import { AbilityContext } from '@src/utility/context/Can'

const PrivateRoute = ({ children, route }) => {
	// ** Hooks & Vars
	const ability = useContext(AbilityContext)
	const user = getUserData() // Dynamically get user data based on environment

	console.log('User data obtained handling private routes', user)

	if (route) {
		let action = null
		let resource = null
		let restrictedRoute = false

		if (route.meta) {
			action = route.meta.action
			resource = route.meta.resource
			restrictedRoute = route.meta.restricted
		}
		if (!user) {
			return <Navigate to='/login' />
		}
		if (user && restrictedRoute) {
			return <Navigate to='/' />
		}
		if (user && restrictedRoute && user.role === 'client') {
			return <Navigate to='/access-control' />
		}
		if (user && !ability.can(action || 'view', resource)) {
			console.log('ability', ability.can(action || 'view', resource))
			console.log('action', action)
			console.log('resource', resource)
			return <Navigate to='/misc/not-authorized' replace />
		}
	}

	return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
