// ** React Imports
import { lazy } from 'react'

const Login = lazy(() => import('../../views/pages/authentication/Login'))

const Users = lazy(() => import('../../views/auth/users'))
const EmailAccounts = lazy(() => import('../../views/auth/email-accounts'))
const BotAccounts = lazy(() => import('../../views/auth/bot-accounts'))
const IpWhitelists = lazy(() => import('../../views/auth/ip-whitelists'))
const IpBlacklists = lazy(() => import('../../views/auth/ip-blacklists'))

const AuthenticationRoutes = [
	{
		path: '/login',
		element: <Login />,
		meta: {
			layout: 'blank',
			publicRoute: true,
			restricted: true
		}
	},
	{
		path: '/auth/users',
		element: <Users />,
		meta: {
			action: 'manage',
			resource: 'auth'
		}
	},
	{
		path: '/auth/email-accounts',
		element: <EmailAccounts />,
		meta: {
			action: 'manage',
			resource: 'email-accounts'
		}
	},
	{
		path: '/auth/bot-accounts',
		element: <BotAccounts />,
		meta: {
			action: 'manage',
			resource: 'bot-accounts'
		}
	},
	{
		path: '/auth/ip-whitelists',
		element: <IpWhitelists />,
		meta: {
			action: 'manage',
			resource: 'ip-whitelists'
		}
	},
	{
		path: '/auth/ip-blacklists',
		element: <IpBlacklists />,
		meta: {
			action: 'manage',
			resource: 'ip-blacklists'
		}
	}
]

export default AuthenticationRoutes
