// ** Icons Import
import { PieChart, Circle } from 'react-feather'

export default [
	{
		id: 'analyticsDash',
		title: 'Analytics',
		icon: <PieChart size={20} />,
		action: 'manage',
		resource: 'dashboard',
		navLink: '/dashboard/analytics'
	}
	/*{
    id: 'dashboards',
    title: 'Dashboards',
    icon: <Home size={20} />,
    badge: 'light-warning',
    badgeText: '2',
    children: [
      {
        id: 'analyticsDash',
        title: 'Analytics',
        icon: <Circle size={12} />,
        navLink: '/dashboard/analytics'
      },
      {
        id: 'eCommerceDash',
        title: 'eCommerce',
        icon: <Circle size={12} />,
        navLink: '/dashboard/ecommerce'
      }
    ]
  }*/
]
