import { lazy } from 'react'

const Mailbox = lazy(() => import('../../views/mailbox'))

const MailboxRoutes = [
	{
		// path: '/mailbox/:folder',
		path: '/mailbox',
		meta: {
			appLayout: true,
			className: 'email-application',
			action: 'manage',
			resource: 'mailbox'
		},
		element: <Mailbox />
	}
]

export default MailboxRoutes
