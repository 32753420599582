// ** Icons Import
import { Map, Briefcase, Layers, MapPin, Paperclip, Users } from 'react-feather'

export default [
	{
		header: 'HRM',
		action: 'manage',
		resource: 'hrm'
	},
	{
		id: 'hrmLocalities',
		title: 'Localities',
		icon: <Map size={20} />,
		action: 'manage',
		resource: 'hrm',
		navLink: '/hrm/localities'
	},
	{
		id: 'hrmCompanies',
		title: 'Companies',
		icon: <Briefcase size={20} />,
		action: 'manage',
		resource: 'hrm',
		navLink: '/hrm/companies'
	},
	{
		id: 'hrmBranches',
		title: 'Branches',
		icon: <MapPin size={20} />,
		action: 'manage',
		resource: 'hrm',
		navLink: '/hrm/branches'
	},
	{
		id: 'hrmDepartments',
		title: 'Departments',
		icon: <Layers size={20} />,
		action: 'manage',
		resource: 'hrm',
		navLink: '/hrm/departments'
	},
	{
		id: 'hrmFunctions',
		title: 'Functions',
		icon: <Paperclip size={20} />,
		action: 'manage',
		resource: 'hrm',
		navLink: '/hrm/functions'
	},
	{
		id: 'hrmEmployees',
		title: 'Employees',
		icon: <Users size={20} />,
		action: 'manage',
		resource: 'hrm',
		navLink: '/hrm/employees'
	}
]
